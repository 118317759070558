<template>
  <div class="news-list">
    <div class="page-title">
      <div class="container">
        <div class="con-title con-title-white">
          <span>公司概况</span>
        </div>
      </div>
    </div>
    <div class="container">
      <news-list
        :titleList="titleList"
        :dataList="dataList"
        :defaultTitle="defaultTitle"
        :total="total"
        :current="current"
        :content="content"
        :titleName="titleName"
        :fileList="fileList"
        routerName="CompanyDetails"
        :themeName="themeName"
        title="公司概况"
        @getChangeId="getChangeId"
        @changePage="changePage"
      ></news-list>
    </div>
  </div>
</template>

<script>
import newsList from "@/views/components/newsList.vue";
import * as api from "@/services/newsList.js";
export default {
  name: "CompanyOverview",
  components: { newsList },
  data() {
    return {
      titleList: [],
      dataList: [],
      defaultTitle: [],
      total: 0,
      current: 1,
      content: "",
      titleName: "",
      fileList: [],
      themeName: "",
    };
  },
  created() {
    //查询新闻栏目列表
    this.getAllLevelTheme();
  },
  methods: {
    changePage(number) {
      this.current = number;
      this.getNoteList(this.defaultTitle[0]);
    },
    getChangeId(id) {
      this.defaultTitle = [id];
      let vm = this;
      this.titleList.map(function (item) {
        if (item.id === id) {
          vm.themeName = item.themename;
        }
      });
      this.current = 1;
      this.getNoteList(id);
    },
    getAllLevelTheme() {
      if (this.titleList && this.titleList.length > 0) {
        if (!this.$route.params.id) {
          this.defaultTitle = [this.titleList[0].id];
        } else {
          this.defaultTitle = [this.$route.params.id];
        }
        this.current = 1;
        //查询当前栏目下的新闻列表
        this.getNoteList(this.defaultTitle);
      } else {
        api.getAllLevelTheme("2.").then((res) => {
          if (res.data.success) {
            this.titleList = res.data.data;
            if (!this.$route.params.id) {
              this.defaultTitle = [this.titleList[0].id];
              this.themeName = this.titleList[0].themename;
            } else {
              let vm = this;
              this.defaultTitle = [this.$route.params.id];
              this.titleList.map(function (item) {
                if (item.id === vm.$route.params.id) {
                  vm.themeName = item.themename;
                }
              });
            }
            this.current = 1;
            //查询当前栏目下的新闻列表
            this.getNoteList(this.defaultTitle);
          }
        });
      }
    },
    getNoteList(id) {
      if (this.themeName === "友情链接" || this.themeName === "战略合作") {
        let obj = {};
        if (this.themeName === "友情链接") {
          obj = {
            type: 2,
            pageNumber: this.current,
            pageSize: 10,
          };
        } else {
          obj = {
            type: 3,
            pageNumber: this.current,
            pageSize: 10,
          };
        }
        api.getAddList(obj).then((res) => {
          if (res.data.success) {
            this.dataList = res.data.data.list;
            this.dataList.map(function (item) {
              item.imgurlPic =
                process.env.VUE_APP_API_BASE_URL +
                "/download?fileCode=" +
                item.sysFldFilepath;
              item.title = item.name;
            });
            this.total = Number(res.data.data.count);
            this.content = "";
            this.titleName = "";
          } else {
            this.dataList = [];
          }
        });
      } else {
        let obj = {
          themeId: id,
          pageNumber: this.current,
          pageSize: 10,
        };
        api.getNoteList(obj).then((res) => {
          if (res.data.success) {
            this.dataList = res.data.data.list;
            this.dataList.map(function (item) {
              item.pubdate = '';
            });
            this.total = Number(res.data.data.count);
            this.content = "";
            this.titleName = "";
            if (this.dataList.length === 1) {
              this.fileList = [];
              api.getNoteContentByNoteId(this.dataList[0].id).then((res) => {
                if (res.data.success) {
                  this.content = res.data.data.content;
                  this.titleName = res.data.data.title;
                  if (
                    res.data.map &&
                    res.data.map.attachmentList &&
                    res.data.map.attachmentList.length > 0
                  ) {
                    this.fileList = res.data.map.attachmentList;
                  }
                } else {
                  this.$message.error("查询失败！");
                }
              });
            }
          }
        });
      }
    },
    onChange(pageNumber) {
      console.log("Page: ", pageNumber);
    },
  },
};
</script>
<style lang="less">
@import "./companyOverview.less";
</style>
